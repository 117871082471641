import React, { memo } from 'react';
import { graphql } from 'gatsby';
import { SanityPageBuilderPageV2 } from '../../../../graphql-types';
import { default as SEO } from '../../../components/SEO';
import { useNavigate } from '@reach/router';
import { default as PageBuilderBase } from './page-builder-base';

export interface PageBuilderTemplateProps {
  data: {
    sanityPageBuilderPageV2: SanityPageBuilderPageV2;
  };
}

export default memo(
  function PageBuilderTemplate(
    props: PageBuilderTemplateProps
  ): React.ReactElement {
    const navigate = useNavigate();
    const data = props?.data;

    // If for some reason the query fails and the props.data are empty redirect to the home page.
    if (!data?.sanityPageBuilderPageV2?.components) {
      if (typeof window !== 'undefined') {
        navigate('/');
      }
      return null;
    }
    const {
      metaDescription,
      metaTitle,
      metaImage,
      noindex
    } = data?.sanityPageBuilderPageV2;

    return (
      <>
        {metaTitle && (
          <SEO
            pageDescription={metaDescription}
            pageTitle={metaTitle}
            noindex={noindex}
            pageImage={metaImage}
          />
        )}
        <PageBuilderBase components={data.sanityPageBuilderPageV2.components} />
      </>
    );
  },
  (prevProps, nextProps) => {
    const prev =
      prevProps?.data?.sanityPageBuilderPageV2?.components?.items.length;
    const next =
      nextProps?.data?.sanityPageBuilderPageV2?.components?.items.length;

    return prev === next;
  }
);

export const query = graphql`
  query PageBuilderV2Query($id: String!) {
    sanityPageBuilderPageV2(id: { eq: $id }) {
      components {
        ...SanityPageBuilderComponentsListFragment
      }
      metaTitle
      noindex
      metaDescription
      theme
      slug {
        current
      }
      metaImage {
        hotspot {
          height
          width
          x
          y
        }
        crop {
          bottom
          left
          right
          top
        }
      }
    }
  }
`;
